import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import HeaderNew from "../../headerFooterSide/HeaderNew";
import TradingView from "../TradingView/TradingView";
import { DateTime_Div } from "../../common/Commons";
import ModalChart from "../../common/ModalChart";
import BB_Strategy_Parameter_Test from "./BB_Strategy_Parameter_Test";
import BB_Stochastic_Parameter_Test from "./BB_Stochastic_Parameter_Test";
import TradingView_Test from "../TradingView/TradingView_Test";

function BackTesting_Manual_Testing({handleLogoutapp}) {


    const [dateFrom, setDateFrom] = useState("2024-07-01");
    const [timeFrom, setTimeFrom] = useState("00:00");
    const [dateTo, setDateTo] = useState(getCurrentFormattedDate());
    const [timeTo, setTimeTo] = useState(getCurrentFormattedTime());


    const [title, setTile] = useState()
    const [hide,set_hide] = useState(false)

    const [strategy_array, set_strategy_array] = useState([]);
    const [current_strategy, set_current_strategy] = useState("BB_Stochastic");

    const [exchange_array, set_exchange_array] = useState([]);
    const [current_exchange, set_current_exchange] = useState("binance");

    const [coin_array, set_coin_array] = useState([]);
    const [current_coin, set_current_coin] = useState("btc");
    const [current_timeframe, set_current_timeframe] = useState("5m");
    const [current_entry_direction, set_current_entry_direction] = useState("sell");


    const [parameter, set_parameter] = useState({});

    const [result_file_path, set_result_file_path] = useState("");
    const [start_trade_time, set_start_trade_time] = useState("");

    const [loading, setLoading] = useState(false);
    
    const handle_strategy_name = (e) => {
        set_current_strategy(e.target.value);
      };
    
    const handle_exchage = (e) => {
        set_current_exchange(e.target.value);
    };

    const handle_coin = (e) => {
        set_current_coin(e.target.value);
    };
    const handle_timeframe = (e) => {
        set_current_timeframe(e.target.value);
    };
    const handle_entry_direction = (e) => {
        set_current_entry_direction(e.target.value);
    };
    function valuetext(value) {
        return `${value}°C`;
      }
    
    function getCurrentFormattedDate() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    
    function getCurrentFormattedTime() {
        const now = new Date();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        // Adjust minutes to the previous multiple of 5
        minutes = Math.floor(minutes / 5) * 5;

        hours = String(hours).padStart(2, '0');
        minutes = String(minutes).padStart(2, '0');
        
        return `${hours}:${minutes}`;
    }



    function getFormattedDateComponents(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hours}:${minutes}`;
        
        return { formattedDate, formattedTime };
    }
    
    function getDates(daysAgo) {
        const now = new Date();
        const pastDate = new Date(now);
        pastDate.setDate(now.getDate() - daysAgo);
        
        const currentDateComponents = getFormattedDateComponents(now);
        const pastDateComponents = getFormattedDateComponents(pastDate);
        
        return {
            currentDate: currentDateComponents.formattedDate,
            currentTime: currentDateComponents.formattedTime,
            pastDate: pastDateComponents.formattedDate,
            pastTime: pastDateComponents.formattedTime
        };
    }

    function chage_period_optimize(days) {
        const dates = getDates(days); // 5시간 이전의 날짜와 현재 날짜
        console.log(`Current Date: ${dates.currentDate}`); // 현재 날짜 (yyyy-mm-dd)
        console.log(`Current Time: ${dates.currentTime}`); // 현재 시간 (hh:mm)
        console.log(`Past Date: ${dates.pastDate}`);       // 5시간 이전의 날짜 (yyyy-mm-dd)
        console.log(`Past Time: ${dates.pastTime}`);       // 5시간 이전의 시간 (hh:mm)

        setDateTo(`${dates.currentDate}`)
        setTimeTo(`${dates.currentTime}`)
        setDateFrom(`${dates.pastDate}`)
        setTimeFrom(`${dates.pastTime}`)

    
    }

    

    function get_backtesting_resource() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
            strategy_name: "strategy_name",
            coin_name: "coin_name",
            entry_type: "entry_type",
        };
        

        axios
            .post(`${python_server_url}/get_backtesting_resource_testing`, data)
            .then((res) => {
                //console.log("res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {

                    set_strategy_array(res.data.result.strategy_array);
                    set_exchange_array(res.data.result.exchange_array);
                    set_coin_array(res.data.result.coin_array);

                    //console.log("get_backtesting_resource , res.data.result.strategy_array ==> " + res.data.result.strategy_array)
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }


    function do_Strategy_testing() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        console.log("do_Strategy_testing !!!!")
        if (check_invalidate() != true) {
           return false
        }
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING


        let temp_from_date = dateFrom + " " + timeFrom + ":00"
        let temp_to_date = dateTo + " " + timeTo + ":00"
       
        const data = {
            strategy_name: current_strategy,
            exchange_name: current_exchange,
            symbol: current_coin,
            interval: current_timeframe,
            backtesting_from_date: temp_from_date,
            backtesting_to_date: temp_to_date,
            entrydirection: current_entry_direction,
            parameter: parameter,
        };
      
        console.log("do_Strategy_testing ,data ==> " + JSON.stringify(data))

        set_result_file_path("")
        setLoading(true);
        axios
            .post(`${python_server_url}/Strategy_testing`, data)
            .then((res) => {
                console.log("do_Strategy_testing , res.data ==> " + JSON.stringify(res.data))
                if (res.data.length !== 0) {
                    console.log("do_Strategy_testing , res.data.candle_file_full_path ==> " + JSON.stringify(res.data.candle_file_full_path))
                    set_result_file_path(res.data.candle_file_full_path)
                    set_start_trade_time(res.data.start_trade_time)

                    setLoading(false);
                } 
            })
            .catch((err) => {
                console.log(err);
                set_result_file_path("")
                setLoading(false);
            });

    }

    function check_invalidate(){

        if (current_strategy == ""){
            alert("strategy is invalidate !")
            return false
        }
        if (current_exchange == ""){
            alert("exchange is invalidate !")
            return false
        }
        if (current_coin == ""){
            alert("coin is invalidate !")
            return false
        }
        if (current_timeframe == ""){
            alert("timeframe is invalidate !")
            return false
        }
        if (dateFrom == ""){
            alert("Date(From) is invalidate !")
            return false
        }
        if (timeFrom == ""){
            alert("Time(From) is invalidate !")
            return false
        }
        if (dateTo == ""){
            alert("Date(To) is invalidate !")
            return false
        }
        if (timeTo == ""){
            alert("Time(To) is invalidate !")
            return false
        }
        if (current_entry_direction == ""){
            alert("Entry Direction is invalidate !")
            return false
        }

        ///
        if (parameter['min_profit_sell_enable'] == ""){
            alert("min_profit_sell_enable is invalidate !")
            return false
        }
        if (parameter['min_profit_sell'] == ""){
            alert("min_profit_sell is invalidate !")
            return false
        }
        if (parameter['min_profit_buy_enable'] == ""){
            alert("min_profit_buy_enable is invalidate !")
            return false
        }
        if (parameter['min_profit_buy'] == ""){
            alert("min_profit_buy is invalidate !")
            return false
        }
        if (parameter['bollinger_length'] == ""){
            alert("bollinger_length is invalidate !")
            return false
        }
        if (parameter['bollinger_dev'] == ""){
            alert("bollinger_dev is invalidate !")
            return false
        }
        if (parameter['bbw_short_set_enable'] == ""){
            alert("bbw_short_set_enable is invalidate !")
            return false
        }
        if (parameter['bbw_short_set_value'] == ""){
            alert("bbw_short_set_value is invalidate !")
            return false
        }
        if (parameter['bbw_long_set_enable'] == ""){
            alert("bbw_long_set_enable is invalidate !")
            return false
        }
        if (parameter['bbw_long_set_value'] == ""){
            alert("bbw_long_set_value is invalidate !")
            return false
        }
        if (parameter['skip_enable'] == ""){
            alert("skip_enable is invalidate !")
            return false
        }
        if (parameter['skip_buy_count'] == ""){
            alert("skip_buy_count is invalidate !")
            return false
        }
        if (parameter['skip_sell_count'] == ""){
            alert("skip_sell_count is invalidate !")
            return false
        }
        if (parameter['short_limit_condition'] == ""){
            alert("short_limit_condition is invalidate !")
            return false
        }
        if (parameter['long_limit_condition'] == ""){
            alert("long_limit_condition is invalidate !")
            return false
        }
        


        return true
    }

    useEffect(() => {
        setTile("BackTesting Optimization")
        get_backtesting_resource()
    }, []);


    useEffect(() => {

       var check = parameter.hasOwnProperty('mini_profit_sell_enable'); 
       if (check == true){
        do_Strategy_testing();

       }else {

       }

    }, [parameter]);


    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div>
                <div style={{margin: "1px 0px 0px 0px"}}>
                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        {/* Comman setting */}
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        <div style={{margin: "0px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Display Filters</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="strategy_name" style={{margin: "0px 0px 0px 0px",  fontSize :"14px",color: "white" }}>Strategy</label> 
                           
                            <select onChange={handle_strategy_name} id="strategy_name" style={{margin: "0px 0px 0px 10px",  fontSize :"14px"}} 
                                    value={current_strategy}>
                                    {
                                    strategy_array && strategy_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>
                            
                            <label htmlFor="exchage" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>Exchage</label> 
                           
                            <select onChange={handle_exchage} id="exchage" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}}  
                                    value={current_exchange}>
                                    {exchange_array && exchange_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>
                            
                            <label htmlFor="coin_name" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>Coin</label> 
                           
                            <select onChange={handle_coin} id="coin_name" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}}  
                                    value={current_coin}>
                                    {coin_array && coin_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>

                            <label htmlFor="timeframe" style={{margin: "0px 0px 0px 20px",  fontSize :"14px",color: "white" }}>TimeFrame</label> 
                            <select onChange={handle_timeframe}  id="timeframe" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}} 
                                value={current_timeframe}> 
                                <option key="0" value="">
                                    select
                                </option>
                                <option key="1" value="1m">
                                    1m
                                </option>
                                <option key="2" value="5m">
                                    5m
                                </option>
                                <option key="3" value="15m">
                                    15m
                                </option>
                                <option key="4" value="30m">
                                    30m
                                </option>
                            </select> 


                            <label htmlFor="entry_type" style={{margin: "0px 0px 0px 20px",  fontSize :"14px",color: "white" }}>Entry Type</label> 
                            <select onChange={handle_entry_direction}  id="entry_type" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}} 
                                value={current_entry_direction}> 
                                <option key="0" value="">
                                    select
                                </option>
                                <option key="1" value="sell">
                                    sell
                                </option>
                                <option key="2" value="buy">
                                    buy
                                </option>
                            </select> 

                        </div>
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        {/* Bactesting Period */}
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        <hr style={{ margin: "30px 0px 0px 0px" , width:"100%", height:"1px" , border:"1px solid white" , borderRadius:"1px" }} ></hr>
                        <div style={{margin: "20px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> BackTesting Period</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <DateTime_Div dateFrom={dateFrom} timeFrom={timeFrom} dateTo={dateTo} timeTo={timeTo}  send_data_from={setDateFrom} send_data_to={setDateTo}  send_time_from={setTimeFrom} set_time_to={setTimeTo} />

                            <label htmlFor="" style={{margin: "0px 10px 0px 20px",  fontSize :"14px", color: "white" }}> Optimization Period</label> 
                    
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(1)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>1D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(2)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>2D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(3)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>3D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(4)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>5D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(5)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>7D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(6)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>21D</button>
                            <button className="btn btn-sm" onClick={() => chage_period_optimize(7)} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color: "white"}}>30D</button>

                        </div>


                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}


                    </div>

                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
                        {/* Parameter Setting */}
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}\
                        <div style={{margin: "0px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Parameter Setting</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                       
                            {
                                current_strategy == 'BB_Strategy'  && (
                                    <><BB_Strategy_Parameter_Test strategy_name={"BB_Strategy"} set_parameter={set_parameter}></BB_Strategy_Parameter_Test></>

                            )}  
                            {
                                current_strategy == 'BB_Stochastic'  && (
                                    <><BB_Stochastic_Parameter_Test strategy_name={"BB_Stochastic"} set_parameter={set_parameter}></BB_Stochastic_Parameter_Test></>
    
                            )}

                        </div>
                        {/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}

                    </div>


                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        <div style={{margin: "0px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Result </label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"100%" ,background:"#1c1b2b"}}>


                        {loading && (
                            <div className="progress-bar">
                                <div className="progress-bar-inner"></div>
                            </div>
                        )}


                        {result_file_path !== ""  ? 
                            <>
                            <div style={{margin: "10px 0px 30px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                    <TradingView_Test type={"optimize"}  result_file_path={result_file_path} strategy_name={current_strategy} exchange_name={current_exchange} coin_type={current_coin} time_frame={current_timeframe} max_pair_count={"0"} current_pair_count={"0"} max_pair_index={"-1"} start_trade_time={start_trade_time}>
                                    </TradingView_Test>
                            </div>
                            </>
                            : null}

                           
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default BackTesting_Manual_Testing;
