import React, { useState, useEffect } from "react";
import axios from "axios";
// import "./arbitrage.css";

import HeaderNew from "../../headerFooterSide/HeaderNew";
import TradingView from "../TradingView/TradingView";
import { DateTime_Div } from "../../common/Commons";
import ModalChart from "../../common/ModalChart";
import ModalParameter from "../../common/ModalParameter";
import BB_Strategy_Parameter_Optimize from "./BB_Strategy_Parameter_Optimize";
import BB_Stochastic_Parameter_Optimize from "./BB_Stochastic_Parameter_Optimize";

import Switch from "react-switch";

function BackTesting_Auto({handleLogoutapp}) {
    const [title, setTile] = useState()
    const [datalogs, setDatalogs] = useState([]);
    const [hide,set_hide] = useState(false)


    const [strategy_array, set_strategy_array] = useState([]);
    const [current_strategy, set_current_strategy] = useState("");

    const [exchange_array, set_exchange_array] = useState([]);
    const [current_exchange, set_current_exchange] = useState("");

    const [coin_array, set_coin_array] = useState([]);
    const [current_coin, set_current_coin] = useState("");

    const [current_entry_direction, set_current_entry_direction] = useState("");


    const [strategy_id, set_strategy_id] = useState("");
    const [strategy_name, set_strategy] = useState("");
    const [exchange_name, set_exchange] = useState("");
    const [coin_type, set_coin] = useState("");
    const [time_frame, set_time_frame] = useState("");
    const [entry_direction, set_entry_direction] = useState("");
    const [start_trade_time, set_start_trade_time] = useState("");
    
    
    const [mini_profit_sell_enable, set_mini_profit_sell_enable] = useState("");
    const [mini_profit_buy_enable, set_mini_profit_buy_enable] = useState("");
    const [mini_profit_sell, set_mini_profit_sell] = useState("");
    const [mini_profit_buy, set_mini_profit_buy] = useState("");

    
    const [stop_loss_sell_enable,set_stop_loss_sell_enable] = useState("");
    const [stop_loss_sell,set_stop_loss_sell] = useState("");

    const [stop_loss_buy_enable,set_stop_loss_buy_enable] = useState("");
    const [stop_loss_buy, set_stop_loss_buy] = useState("");


    const [skip_enable, set_skip_enable] = useState("");
    const [skip_sell_count, set_skip_sell_count] = useState("");
    const [skip_buy_count, set_skip_buy_count] = useState("");
    const [bollenger_length, set_bollenger_length] = useState("");
    const [bollenger_de, set_bollenger_de] = useState("");
    const [bbw_short_set_value, set_bbw_short_set_value] = useState("");
    const [bbw_long_set_value, set_bbw_long_set_value] = useState("");

    const [shortlimitcondition, set_shortlimitcondition] = useState("");
    const [longlimitcondition, set_longlimitcondition] = useState("");


    const [result_file_path, set_result_file_path] = useState("");

    const [operation, set_operation] = useState("");

    const [max_pair_count, set_max_pair_count] = useState("");
    const [current_pair_count, set_current_pair_count] = useState("");
    const [max_pair_index, set_max_pair_index] = useState("");
    
    const [parameter_json, set_parameter_json] = useState({});


    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    // modal
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [modalOpen, setModalOpen] = useState(false);
    const openModal = (name) => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    /////////////////////////////////////////////////////////////////////////////////////////////////////////



    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    // modal
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [modalParameterOpen, setModalParameterOpen] = useState(false);
    const openModalParameterOpen = (name) => {
        setModalParameterOpen(true);
    };

    const closeModalParameter = () => {
        setModalParameterOpen(false);
    };
    /////////////////////////////////////////////////////////////////////////////////////////////////////////



    function HideView(){
        set_hide(false)
    }
    function DisplayView(){
        set_hide(true)
    }


    const handle_strategy_name = (e) => {
        set_current_strategy(e.target.value);
      };
    
    const handle_exchage = (e) => {
    set_current_exchange(e.target.value);
    };

    const handle_coin = (e) => {
    set_current_coin(e.target.value);
    };
    const handle_entry_direction = (e) => {
    set_current_entry_direction(e.target.value);
    };

    function SetParameter(strategy_name,exchange_name,coin_type,time_frame){

        set_strategy(strategy_name);
        set_exchange(exchange_name);
        set_coin(coin_type);
        set_time_frame(time_frame);
        
        setModalParameterOpen(true);
    }

    

    function get_backtesting_resource() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_name: "strategy_name",
            coin_name: "coin_name",
            entry_type: "entry_type",
        };
        axios
            .post(`${python_server_url}/get_backtesting_resource`, data)
            .then((res) => {
                //console.log("res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {

                    set_strategy_array(res.data.result.strategy_array);
                    set_exchange_array(res.data.result.exchange_array);
                    set_coin_array(res.data.result.coin_array);

                    //console.log("get_backtesting_resource , res.data.result.strategy_array ==> " + res.data.result.strategy_array)
                } else {
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }


    function get_backtesting_status_all() {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        //console.log("python_server_url ==> " + python_server_url)

        const data = {
            strategy_name: current_strategy,
            coin_type: current_coin,
            exchange_name: current_exchange,
            entry_direction: current_entry_direction,
        };
        axios
            .post(`${python_server_url}/get_list_optimized_v3`, data)
            .then((res) => {
                //console.log("get_backtesting_status_all, res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {
                    setDatalogs(res.data.result);

                } else {
                    setDatalogs([]);
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }

    function get_backtesting_status_detail(strategy_id) {
        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_id: strategy_id,
        };
        axios
            .post(`${python_server_url}/get_backtesting_status_detail`, data)
            .then((res) => {
                //console.log("get_backtesting_status_detail , res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.result.length !== 0) {

                    set_strategy_id(res.data.result.id)
                    
                    set_strategy(res.data.result.strategy_name);
                    set_exchange(res.data.result.exchange_name);
                    set_coin(res.data.result.coin_type);
                    set_time_frame(res.data.result.time_frame);
                    set_entry_direction(res.data.result.entry_direction);

                    set_mini_profit_sell_enable(res.data.result.mini_profit_sell_enable);
                    set_mini_profit_buy_enable(res.data.result.mini_profit_buy_enable);

                    set_mini_profit_sell(res.data.result.mini_profit_sell);
                    set_mini_profit_buy(res.data.result.mini_profit_buy);
                    
                    set_stop_loss_sell_enable(res.data.result.stop_loss_sell_enable);
                    set_stop_loss_buy_enable(res.data.result.stop_loss_buy_enable);
                    
                    set_stop_loss_sell(res.data.result.stop_loss_sell);
                    set_stop_loss_buy(res.data.result.stop_loss_buy);

                    set_skip_enable(res.data.result.skip_enable);
                    set_skip_sell_count(res.data.result.skip_sell_count);
                    set_skip_buy_count(res.data.result.skip_buy_count);

                    set_bollenger_length(res.data.result.bollenger_length);
                    set_bollenger_de(res.data.result.bollenger_de);

                    set_bbw_short_set_value(res.data.result.bbw_short_set_value);
                    set_bbw_long_set_value(res.data.result.bbw_long_set_value);

                    set_shortlimitcondition(res.data.result.shortlimitcondition);
                    set_longlimitcondition(res.data.result.longlimitcondition);

                    set_result_file_path(res.data.result.result_file_path_to_sentinel);
                    //console.log("get_backtesting_status_detail , res.data.result.result_file_path_to_sentinel ==> " + res.data.result.result_file_path_to_sentinel)

                    set_operation(res.data.result.operation)
                    

                    set_max_pair_count(res.data.result.max_pair_count)
                    set_current_pair_count(res.data.result.current_pair_count)
                    set_max_pair_index(res.data.result.max_pair_index)

                    var parameter_json = res.data.result.parameter_json
                    if (parameter_json != "" && parameter_json != undefined){
                        set_parameter_json(JSON.parse( parameter_json.replace(/'/g, '"')))
                    }

                    set_start_trade_time(res.data.result.from_date_epoc)

                    setModalOpen(true);

                    

                } else {
                   
                }
            })
            .catch((err) => {
                console.log(err);
                setDatalogs([]);
            });
    }

    const dataItemToKeyValues = (item) => {

       
        const entries = Object.entries(item);
        const listItems = entries.map(([key, value]) => (

            key!=="exchange_name" && key!=="coin_type" && key!=="timeframe" && key!=="entrydirection" && (
                <>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "white" }}>{repace_item(key)}:</label>
                <label htmlFor="" style={{margin: "0px 0px 0px 10px",  fontSize :"14px", color: "#FFC000" }}>{value}</label>
                </>
            )
 
        ));
        return (
                <div style={{width:"100%" ,display:"flex",flexWrap:"wrap" ,gap:"10px",margin: "0px 0px 0px 0px"}}>
                    {listItems}
                </div>
            )
    };
    
    function repace_item(iputstr){

        iputstr = iputstr.replace("bollenger_length","bollinger_length")
        iputstr = iputstr.replace("bollenger_dev","bollinger_dev")
        iputstr = iputstr.replace("mini_","min_")
        iputstr = iputstr.replace("shortlimitcondition","short_limit_condition")
        iputstr = iputstr.replace("longlimitcondition","long_limit_condition")



        iputstr = iputstr.replace("st_short_condition_s","st_short_condition")
        iputstr = iputstr.replace("st_long_condition_s","st_long_condition")


        iputstr = iputstr.replace("st_periodk","st_period_k")
        iputstr = iputstr.replace("st_smoothk","st_smooth_k")
        iputstr = iputstr.replace("st_periodd","st_period_d")
        
        /*
        iputstr = iputstr.replace("short_st_periodk","short_st_period_k")
        iputstr = iputstr.replace("short_st_smoothk","short_st_smooth_k")
        iputstr = iputstr.replace("short_st_periodd","short_st_period_d")

        iputstr = iputstr.replace("long_st_periodk","long_st_period_k")
        iputstr = iputstr.replace("long_st_smoothk","long_st_smooth_k")
        iputstr = iputstr.replace("long_st_periodd","long_st_period_d")
        */
        
        return iputstr
    }

      
    function Update_short_order(strategy_name,exchange_name,coin_type,time_frame){

        if (window.confirm('Do you want to apply ?')) {
    
    
            var short_order_html= strategy_name + exchange_name + coin_type + time_frame + "_text_short_order_html"
           
            //alert(short_order_html)
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
            const data = {
                strategy_name : strategy_name,
                exchange_name : exchange_name,
                coin_type : coin_type,
                time_frame : time_frame,
                short_order : document.getElementById(short_order_html).value ,
            
            };

            //alert(JSON.stringify(data))
            var turl = "update_short_order_opti"
        
            axios
              .post(`${python_server_url}/${turl}`, data)
              .then((res) => {
                console.log(JSON.stringify(res.data))
                //alert(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                    get_backtesting_status_all()
                }
              })
              .catch((err) => {
                alert(err);
                console.log(err);
              });
    
        }
    
      }

  function Update_long_order(strategy_name,exchange_name,coin_type,time_frame){

    if (window.confirm('Do you want to apply ?')) {


        var long_order_html= strategy_name + exchange_name + coin_type + time_frame  + "_text_long_order_html"
       
        //alert(long_order_html)
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
            strategy_name : strategy_name,
            exchange_name : exchange_name,
            coin_type : coin_type,
            time_frame : time_frame,
            long_order : document.getElementById(long_order_html).value ,
        
        };
        //alert(JSON.stringify(data))

        var turl = "update_long_order_opti"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }

    function chage_status_update(checked,strategy_name,exchange_name,coin_type,time_frame){

        if (window.confirm('Do you want to apply ?')) {

            //const python_server_url = "https://aitrading.lukiumedia.com"
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING

            //console.log("python_server_url ==> " + python_server_url)


            var operation = ""
            if (checked){
                operation = "false"
            }else {
                operation = "true"

            }

            const data = {
                check: operation,
                strategy_name: strategy_name,
                exchange_name: exchange_name,
                coin_type: coin_type,
                time_frame: time_frame,
            };
            axios
                .post(`${python_server_url}/chage_status_update`, data)
                .then((res) => {
                    //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                    if (res.data.msg == "ok") {
                       alert("Operation is updated !")
                       get_backtesting_status_all()
                    } else {
    
                       alert(res.data.error)
                       
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
       
       
    }
    function chage_period_optimize(period,strategy_name,exchange_name,coin_type,time_frame) {

        if (window.confirm('Do you want to apply ?')) {
            //const python_server_url = "https://aitrading.lukiumedia.com"
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING

            //console.log("python_server_url ==> " + python_server_url)
            const data = {
                period: period,
                strategy_name: strategy_name,
                exchange_name: exchange_name,
                coin_type: coin_type,
                time_frame: time_frame,
            };

            axios
                .post(`${python_server_url}/chage_period_optimize`, data)
                .then((res) => {
                    //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                    if (res.data.msg == "ok") {
                       alert("Period is updated !")
                       get_backtesting_status_all()
                    } else {
    
                       alert(res.data.error)
                       
                    }
                })
                .catch((err) => {
                    console.log(err);
                });


        }

    }

    function forece_optimize(strategy_name,exchange_name,coin_type,time_frame){


        if (window.confirm('Do you want to apply ?')) {
            //const python_server_url = "https://aitrading.lukiumedia.com"
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
           
            //console.log("python_server_url ==> " + python_server_url)
            const data = {
                strategy_name: strategy_name,
                exchange_name: exchange_name,
                coin_type: coin_type,
                time_frame: time_frame,
            };
            axios
                .post(`${python_server_url}/forece_optimize`, data)
                .then((res) => {
                    //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                    if (res.data.msg == "ok") {
                       alert("Operation is updated !")
                       get_backtesting_status_all()
                    } else {
    
                       alert(res.data.error)
                       
                    }
                })
                .catch((err) => {
                    console.log(err);
                });


        }
       
    }


    function chage_status_optimize(checked,strategy_name,exchange_name,coin_type,time_frame){


        if (window.confirm('Do you want to apply ?')) {
            //const python_server_url = "https://aitrading.lukiumedia.com"
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
           
            var operation = ""
            if (checked){
                operation = "false"
            }else {
                operation = "true"

            }

            //console.log("python_server_url ==> " + python_server_url)
            const data = {
                check: operation,
                strategy_name: strategy_name,
                exchange_name: exchange_name,
                coin_type: coin_type,
                time_frame: time_frame,
            };
            axios
                .post(`${python_server_url}/chage_status_optimize`, data)
                .then((res) => {
                    //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                    if (res.data.msg == "ok") {
                       alert("Operation is updated !")
                       get_backtesting_status_all()
                    } else {
    
                       alert(res.data.error)
                       
                    }
                })
                .catch((err) => {
                    console.log(err);
                });


        }
       
    }

    function StopStartegy(strategy_id){

        //const python_server_url = "https://aitrading.lukiumedia.com"
        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
       
        //console.log("python_server_url ==> " + python_server_url)
        const data = {
            strategy_id: strategy_id,
        };
        axios
            .post(`${python_server_url}/set_backtesting_stop`, data)
            .then((res) => {
                //console.log("set_backtesting_stop , res.data.result ==> " + JSON.stringify(res.data.result))
                if (res.data.msg == "ok") {
                   alert("Operation is updated !")
                   get_backtesting_status_all()
                } else {

                   alert(res.data.error)
                   
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }


    function Refresh(){
        get_backtesting_status_all()
    }
    function ViewChart(strategy_id){
        console.log("ViewChart, strategy_id  ==>" + strategy_id )
        get_backtesting_status_detail(strategy_id)
        
    }

    useEffect(() => {
        setTile("BackTesting Optimization")
        get_backtesting_resource()
    }, []);


    useEffect(() => {
        get_backtesting_status_all()
    }, [current_strategy,current_exchange,current_coin,current_entry_direction]);

    useEffect(() => {
        const intervalId = setInterval(() => {
        //window.location.reload()
        get_backtesting_status_all()
        }, 1000*3);
        return () => clearInterval(intervalId);
    }, []);
    
    function json_to_string(input){

        let c_c_f = JSON.parse(String(input));
        return c_c_f

    }

    return (
        <div>
            {/* tile */}
            <HeaderNew handleLogoutapp ={handleLogoutapp} Title={title} />
            <br></br>
            <div>
                <div style={{margin: "1px 0px 0px 0px"}}>
                    <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                        <div style={{margin: "0px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Display Filters</label> 
                        </div>

                        <div style={{margin: "10px 0px 0px 0px" ,width:"95%" ,background:"#1c1b2b"}}>
                            <label htmlFor="strategy_name" style={{margin: "0px 0px 0px 0px",  fontSize :"14px",color: "white" }}>Strategy</label> 
                           
                            <select onChange={handle_strategy_name} id="strategy_name" style={{margin: "0px 0px 0px 10px",  fontSize :"14px"}} 
                                    value={current_strategy}>
                                    {
                                    strategy_array && strategy_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>
                            
                            <label htmlFor="exchage" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>Exchage</label> 
                           
                            <select onChange={handle_exchage} id="exchage" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}}  
                                    value={current_exchange}>
                                    {exchange_array && exchange_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>
                            
                            <label htmlFor="coin_name" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}>Coin</label> 
                           
                            <select onChange={handle_coin} id="coin_name" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}}  
                                    value={current_coin}>
                                    {coin_array && coin_array.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option}
                                        </option>
                                    ))}
                            </select>

                            <label htmlFor="entry_type" style={{margin: "0px 0px 0px 20px",  fontSize :"14px",color: "white" }}>Entry Type</label> 
                            <select onChange={handle_entry_direction}  id="entry_type" style={{margin: "0px 0px 0px 10px",  fontSize :"14px" , width: "100px"}} 
                                value={current_entry_direction}> 
                                <option key="1" value="all">
                                    all
                                </option>
                                <option key="2" value="sell">
                                    sell
                                </option>
                                <option key="3" value="buy">
                                    buy
                                </option>
                            </select> 
                        </div>
                    </div>
                   
                    {
                        hide === false && (

                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                            <div style={{margin: "0px 0px 0px 0px" ,width:"100%"}}>
                                <label htmlFor="" style={{margin: "0px 0px 0px 0px",  fontSize :"14px", color: "white" }}> Result</label> 
                                <button className="btn btn-success btn-sm" style={{margin: "0px 0px 0px 30px" }} onClick={(e) => Refresh()}>
                                    Refresh
                                </button>
                            </div>

                            <div style={{width:"100%" ,gap:"10px",margin: "0px 0px 0px 0px"}}>

                                { datalogs && datalogs.map((strategy, i) => (

                                        <div style={{margin:"auto", flexWrap:"wrap", margin: "23px 0px 0px 0px",width:"100%"}}>
                                           
                                           <div style={{margin: "0px 0px 0px 0px",width:"100%"}} >
                                                <div style={{margin: "0px 0px 0px 0px"}}>
                                                    <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px", color: "white" }}>Strategy : </label> <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px",fontWeight : "800" , color: "#FFC000" }}>{strategy['strategy_name'].toUpperCase()}</label> 
                                                    <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px", color: "white" }}>Exchange : </label> <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px", fontWeight : "800" , color: "#FFC000" }}>{strategy['exchange_name'].toUpperCase()}</label> 
                                                    <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px", color: "white" }}>Coin :  </label><label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"17px", fontWeight : "800" , color: "#FFC000" }}>{strategy['coin_type'].toUpperCase()}</label> 
                                                </div>

                                                {strategy && strategy['time_frame'] && strategy['time_frame'].map((time_frame_sub, a) => (
                                                (
                                                <div style={{margin: "10px 0px 0px 0px" , color: "white" }}>
                                                   
                                                        {
                                                        time_frame_sub['row_data'] && time_frame_sub['row_data'].map((buy_sell_sub2, b) => (
                                                        <>
                                                            {
                                                            (Object.keys(buy_sell_sub2).toString().toUpperCase()==='SELL')&&
                                                            (
                                                                <>
                                                                { 

                                                                    buy_sell_sub2['sell'] && buy_sell_sub2['sell'].map((buy_sell_sub_sell, c) => (
                                                                    <>
                                                                        <div style={{margin: "0px 0px 0px 0px"}}>
                                                                            <button className="btn btn-warning btn-sm" style={{margin: "0px 20px 0px 20px",  fontSize :"14px", color: "black" }} onClick={() => SetParameter( buy_sell_sub_sell['strategy_name'].toString(),strategy['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}>&nbsp;&nbsp;&nbsp;Set&nbsp;&nbsp;&nbsp;</button>

                                                                            <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", color: "white" }}>TimeFrame : </label><label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", fontWeight : "800" , color: "#FFC000" }}>{buy_sell_sub_sell['time_frame']}</label> 

                                                                            <label htmlFor="" style={{margin: "0px 0px 0px 20px",  fontSize :"14px", color: "white" }}> Optimization Period</label> 

                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("24" ,buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "24" ? "#FFC000" : "white"}}>1D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("48", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "48" ? "#FFC000" : "white" }}>2D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("72", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "72" ? "#FFC000" : "white" }}>3D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("120", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "120" ? "#FFC000" : "white" }}>5D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("168", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "168" ? "#FFC000" : "white" }}>7D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("504", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "504" ? "#FFC000" : "white" }}>21D</button>
                                                                            <button className="btn btn-sm" onClick={() => chage_period_optimize("720", buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} style={{ margin: "0px 0px 0px 5px", border: "2px solid white" ,color:  buy_sell_sub_sell['period'].toString() === "720" ? "#FFC000" : "white" }}>30D</button>

                                                                            
                                                                            <label className="switch" style={{margin: "0px 0px 0px 50px"}}>
                                                                                <button className="btn btn-sm" style={{ backgroundColor:"#FFBF00", color: "#000000" ,  marginLeft: "auto" }} onClick={() => forece_optimize(buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())} >Optimize(Force)</button>
                                                                            </label>
                                                                            
                                                                            <label htmlFor="from_date" style={{margin: "0px 0px 0px 80px",  fontSize :"14px", color: "white" }}>Optimize</label>
                                                                            <label className="switch" style={{margin: "0px 0px 0px 5px"}}>
                                                                                {/*
                                                                                <input type="checkbox" id={buy_sell_sub_sell['strategy_name'].toString()+ "_" + buy_sell_sub_sell['exchange_name'].toString()+ "_" + buy_sell_sub_sell['coin_type'].toString()+ "_" + buy_sell_sub_sell['time_frame'].toString()}
                                                                                    checked={buy_sell_sub_sell['operation_optimize'].toString() === "running" ? true : false}  
                                                                                    onChange={(e) => chage_status_optimize(e.currentTarget.checked, buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}/>  
                                                                                <span className="slider round"></span>
                                                                                */}
                                                                                <Switch
                                                                                    id={buy_sell_sub_sell['strategy_name'].toString()+ "_" + buy_sell_sub_sell['exchange_name'].toString()+ "_" + buy_sell_sub_sell['coin_type'].toString()+ "_" + buy_sell_sub_sell['time_frame'].toString()}
                                                                                    checked={buy_sell_sub_sell['operation_optimize'].toString() === "running" ? true : false}  
                                                                                    onChange={(e) => chage_status_optimize(buy_sell_sub_sell['operation_optimize'].toString() === "running" ? true : false, buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}  
                                                                                    className="react-switch"
                                                                                />
                                                                        

                                                                            </label>


                                                                       

                                                                            <label htmlFor="from_date" style={{margin: "0px 0px 0px 5px",  fontSize :"14px", color: "white" }}>Update to live</label>
                                                                            <label className="switch" style={{margin: "0px 0px 0px 5px"}}>
                                                                                {/*
                                                                                <input type="checkbox" id={buy_sell_sub_sell['strategy_name'].toString()+ "_" + buy_sell_sub_sell['exchange_name'].toString()+ "_" + buy_sell_sub_sell['coin_type'].toString()+ "_" + buy_sell_sub_sell['time_frame'].toString()}
                                                                                    checked={buy_sell_sub_sell['operation'].toString() === "running" ? true : false}  
                                                                                    onChange={(e) => chage_status_update(e.currentTarget.checked, buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}/>  
                                                                                <span className="slider round"></span>
                                                                                */}

                                                                                <Switch
                                                                                    id={buy_sell_sub_sell['strategy_name'].toString()+ "_" + buy_sell_sub_sell['exchange_name'].toString()+ "_" + buy_sell_sub_sell['coin_type'].toString()+ "_" + buy_sell_sub_sell['time_frame'].toString()}
                                                                                    checked={buy_sell_sub_sell['operation'].toString() === "running" ? true : false}  
                                                                                    onChange={(e) => chage_status_update(buy_sell_sub_sell['operation'].toString() === "running" ? true : false, buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}  
                                                                                    className="react-switch"
                                                                                />

                                                                            </label>
                                                                            {/* 
                                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>Short Order:</label>  
                                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}></label>
                                                                            <input type="text" id={ buy_sell_sub_sell['strategy_name'].toString()+buy_sell_sub_sell['exchange_name'].toString()+buy_sell_sub_sell['coin_type'].toString()+buy_sell_sub_sell['time_frame'].toString()+"_text_short_order_html"}  name={buy_sell_sub_sell['strategy_name'].toString()+buy_sell_sub_sell['exchange_name'].toString()+buy_sell_sub_sell['coin_type'].toString()+"_text_short_order_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={buy_sell_sub_sell['short_order'].toString()}  />
                                                                            <button className="btn btn-primary btn-sm" style={{ marginLeft: "auto" , margin: "0px 0px 0px 20px"}}  onClick={() => Update_short_order( buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}>Update</button>
                                                                            

                                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  color: "#FFC000" }}>Long Order:</label>  
                                                                            <label style={{margin: "0px 0px 0px 10px",  fontSize :"12px",  cursor: "pointer",color: "white" }}></label>
                                                                            <input type="text" id={ buy_sell_sub_sell['strategy_name'].toString()+buy_sell_sub_sell['exchange_name'].toString()+buy_sell_sub_sell['coin_type'].toString()+buy_sell_sub_sell['time_frame'].toString()+"_text_long_order_html"}  name={buy_sell_sub_sell['strategy_name'].toString()+buy_sell_sub_sell['exchange_name'].toString()+buy_sell_sub_sell['coin_type'].toString()+"_text_long_order_html"}  style={{  margin: "0px 0px 0px 10px", width: "30px" }} defaultValue={buy_sell_sub_sell['long_order'].toString()}  />
                                                                            <button className="btn btn-primary btn-sm" style={{ marginLeft: "auto" , margin: "0px 0px 0px 20px"}}  onClick={() => Update_long_order( buy_sell_sub_sell['strategy_name'].toString(),buy_sell_sub_sell['exchange_name'].toString(),buy_sell_sub_sell['coin_type'].toString(),buy_sell_sub_sell['time_frame'].toString())}>Update</button>
                                                                            */}

                                                                            
                                                                        </div>
                                                                    </>

                                                                    ))

                                                                    }
                                                                </>
                                                            )
                                                            }

                                                            {
                                                            (Object.keys(buy_sell_sub2).toString().toUpperCase()==='BUY')&&
                                                            (
                                                                <>
                                                                { 

                                                                    buy_sell_sub2['buy'] && buy_sell_sub2['buy'].map((buy_sell_sub_sell, d) => (
                                                                    <>
                                                                        <div style={{margin: "0px 0px 0px 0px"}}>

                                                                            <div style={{margin: "10px 0px 0px 0px",width:"100%"}}>
                                                                                <table style={{ fontSize :"14px"}}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{background: "#366CD0"}} >Entry Direction</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Stragety</th>
                                                                                        <th style={{background: "#366CD0"}} >Coin</th>
                                                                                        <th style={{background: "#366CD0"}} >TF</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >From Date</th>
                                                                                        <th style={{background: "#366CD0"}} >To Date</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Percent Profitable</th>>*/}
                                                                                        <th style={{background: "#366CD0"}} >Increased Ratio</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Fiat Profit</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >Trades</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Real Pair Count</th>>*/}
                                                                                    {/* <th style={{background: "#366CD0"}} >Max Pair Count</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Operation</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >Time</th>
                                                                                        <th style={{background: "#366CD0"}} >Details</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr className="logTr" key={d} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                                                            <td>{buy_sell_sub_sell.entry_direction.toUpperCase()}</td>
                                                                                            {/*<td>{buy_sell_sub_sell.strategy_name.toUpperCase()}</td>
                                                                                            <td>{buy_sell_sub_sell.coin_type.toUpperCase()}</td>
                                                                                            <td>{buy_sell_sub_sell.time_frame}</td>*/}
                                                                                            <td>{buy_sell_sub_sell.from_date_local}</td>
                                                                                            <td>{buy_sell_sub_sell.to_date_local}</td>
                                                                                            {/*<td>{parseFloat(buy_sell_sub_sell.profitable_trade).toFixed(1)} %</td>>*/}
                                                                                            <td>{parseFloat(buy_sell_sub_sell.increased_asset_ratio).toFixed(1)} %</td>
                                                                                            {/*<td>{parseFloat(buy_sell_sub_sell.fiat_profit_percent).toFixed(1)} %</td>*/}
                                                                                            <td>{buy_sell_sub_sell.closed_trades}</td>
                                                                                            {/*<td>{buy_sell_sub_sell.current_pair_count}</td>>*/}
                                                                                            {/*<td>{buy_sell_sub_sell.max_pair_count}</td>>*/}
                                                                                            {/*
                                                                                            <td>{buy_sell_sub_sell.operation}&nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-primary btn-sm" onClick={() => StopStartegy(buy_sell_sub_sell.id)} style={{ marginLeft: "auto" }}>Stop</button></td>
                                                                                            */}
                                                                                            <td>{buy_sell_sub_sell.update_time}</td>
                                                                                            <td><button className="btn btn-primary btn-sm" onClick={() => ViewChart(buy_sell_sub_sell.id)} style={{ marginLeft: "auto" }}>View</button></td>
                                                                                        </tr>
                                                                                </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </>

                                                                    ))

                                                                    }
                                                               
                                                                </>
                                                            )
                                                            }
                                                            {
                                                            (Object.keys(buy_sell_sub2).toString().toUpperCase()==='SELL')&&
                                                            (
                                                                <>
                                                                { 

                                                                    buy_sell_sub2['sell'] && buy_sell_sub2['sell'].map((buy_sell_sub_buy, d) => (
                                                                    <>
                                                                        <div style={{margin: "0px 0px 0px 0px"}}>

                                                                            <div style={{margin: "10px 0px 0px 0px",width:"100%"}}>
                                                                                <table style={{ fontSize :"14px"}}>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{background: "#366CD0"}} >Entry Direction</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Stragety</th>
                                                                                        <th style={{background: "#366CD0"}} >Coin</th>
                                                                                        <th style={{background: "#366CD0"}} >TF</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >From Date</th>
                                                                                        <th style={{background: "#366CD0"}} >To Date</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Percent Profitable</th>>*/}
                                                                                        <th style={{background: "#366CD0"}} >Increased Ratio</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Fiat Profit</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >Trades</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Real Pair Count</th>>*/}
                                                                                    {/* <th style={{background: "#366CD0"}} >Max Pair Count</th>
                                                                                        {/*<th style={{background: "#366CD0"}} >Operation</th>*/}
                                                                                        <th style={{background: "#366CD0"}} >Time</th>
                                                                                        <th style={{background: "#366CD0"}} >Details</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr className="logTr" key={d} style={{ background: i%2 === 0 ? "#142440​" :"#233E6F" }}>
                                                                                            <td>{buy_sell_sub_buy.entry_direction.toUpperCase()}</td>
                                                                                            {/*<td>{buy_sell_sub_buy.strategy_name.toUpperCase()}</td>
                                                                                            <td>{buy_sell_sub_buy.coin_type.toUpperCase()}</td>
                                                                                            <td>{buy_sell_sub_buy.time_frame}</td>*/}
                                                                                            <td>{buy_sell_sub_buy.from_date_local}</td>
                                                                                            <td>{buy_sell_sub_buy.to_date_local}</td>
                                                                                            {/*<td>{parseFloat(buy_sell_sub_buy.profitable_trade).toFixed(1)} %</td>>*/}
                                                                                            <td>{parseFloat(buy_sell_sub_buy.increased_asset_ratio).toFixed(1)} %</td>
                                                                                            {/*<td>{parseFloat(buy_sell_sub_buy.fiat_profit_percent).toFixed(1)} %</td>*/}
                                                                                            <td>{buy_sell_sub_buy.closed_trades}</td>
                                                                                            {/*<td>{buy_sell_sub_buy.current_pair_count}</td>>*/}
                                                                                            {/*<td>{buy_sell_sub_buy.max_pair_count}</td>>*/}
                                                                                            {/*
                                                                                            <td>{buy_sell_sub_buy.operation}&nbsp;&nbsp;&nbsp;&nbsp;<button className="btn btn-primary btn-sm" onClick={() => StopStartegy(buy_sell_sub_buy.id)} style={{ marginLeft: "auto" }}>Stop</button></td>
                                                                                            */}
                                                                                            <td>{buy_sell_sub_buy.update_time}</td>
                                                                                            <td><button className="btn btn-primary btn-sm" onClick={() => ViewChart(buy_sell_sub_buy.id)} style={{ marginLeft: "auto" }}>View</button></td>
                                                                                        </tr>
                                                                                </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </>

                                                                    ))
                                                                        
                                                                    }
                                                               
                                                                </>
                                                            )
                                                            }

                                                        
                                                        </>
                                                        
                                                     ))

                                                    }
                                                </div>
                                                )
                                                ))}
                                            </div>


                                        </div>
                                  ))}

                            </div>

                        </div>
                    ) }

                  
                </div>
            </div>

            <ModalChart
                    open={modalOpen}
                    close={closeModal}
                    header={
                        <>
                            <button className="close" onClick={closeModal}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }> 
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <div style={{ width: "100%", overflowY :"scroll" , height:"800px"}}>
                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            <div style={{margin: "0px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>

                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>[ Strategy : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{strategy_name.toUpperCase()}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Exchange : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{exchange_name.toUpperCase()}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Coin : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{coin_type.toUpperCase()} </label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>TimeFrame : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}> {time_frame}</label> </label> 
                                <label htmlFor="from_date" style={{margin: "0px 0px 0px 10px",  fontSize :"15px", color: "white" }}>Entry Direction : <label htmlFor="from_date" style={{ fontSize :"15px", fontWeight : "800" , color: "#FFC000"}}>{entry_direction.toUpperCase()}</label>  ]</label> 
                                
                            </div>

                            <div style={{margin: "10px 0px 0px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <li>{dataItemToKeyValues(parameter_json)}</li>
                            </div> 
                        </div>
                        
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            {result_file_path !== ""  ? 
                            <>
                            <div style={{margin: "10px 0px 30px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                    <TradingView strategy_id={strategy_id} type={"optimize"}  result_file_path={result_file_path} strategy_name={strategy_name} exchange_name={exchange_name} coin_type={coin_type} time_frame={time_frame} max_pair_count={max_pair_count} current_pair_count={current_pair_count} max_pair_index={max_pair_index} start_trade_time={start_trade_time}></TradingView>
                            </div>
                            </>
                            : null}
                        </div>
                       
                        {/* ////////////////////////////////////////  */}
                    </div>
            </ModalChart>

            <ModalParameter
                    open={modalParameterOpen}
                    close={closeModalParameter}
                    header={
                        <>
                            <button className="close" onClick={closeModalParameter}>
                                {" "}
                                &times;{" "}
                            </button>
                        </>
                    }> 
                    {/* Modal.js <main> {props.children} </main>에 내용이 입력된다.  */}
                    <div style={{ width: "100%", overflowY :"scroll" , height:"800px"}}>
                        {/* ////////////////////////////////////////  */}
                        <div style={{borderRadius:"10px", paddingTop:"10px", paddingLeft:"10px", paddingBottom:"10px", margin: "0px 0px 3px 0px" ,width:"100%" ,background:"#1c1b2b"}}>
                            
                            {strategy_name === "BB_Strategy"  ? 
                            <>
                            <div style={{margin: "10px 0px 30px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <BB_Strategy_Parameter_Optimize setModalParameterOpen={setModalParameterOpen} strategy_name={strategy_name} exchange_name={exchange_name} coin_type={coin_type} time_frame={time_frame} entry_direction={entry_direction} />
                            </div>
                            </>
                            : 
                            <>
                            <div style={{margin: "10px 0px 30px 0px" ,width:"99%" ,background:"#1c1b2b"}}>
                                <BB_Stochastic_Parameter_Optimize setModalParameterOpen={setModalParameterOpen}  strategy_name={strategy_name} exchange_name={exchange_name} coin_type={coin_type} time_frame={time_frame} entry_direction={entry_direction} />
                            </div>
                            </>
                            
                            }

                        </div>
                       
                        {/* ////////////////////////////////////////  */}
                    </div>
            </ModalParameter>
        </div>
    );
}

export default BackTesting_Auto;
