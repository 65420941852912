
import React, { useState, useEffect } from "react";
import axios from "axios";

export function DevideSelector({ strategy_id,devide_type,get_backtesting_status_all}) {



  function chage_status_devide_type(devide_type,strategy_id){

    if (window.confirm('Do you want to apply ?')) {

        const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
        const data = {
          strategy_id : strategy_id,
          devide_type : devide_type
        };
        var turl = "chage_status_devide_type"
    
        axios
          .post(`${python_server_url}/${turl}`, data)
          .then((res) => {
            console.log(JSON.stringify(res.data))
            //alert(JSON.stringify(res.data))
            if (res.data.status == "ok") {
                get_backtesting_status_all()
            }
          })
          .catch((err) => {
            alert(err);
            console.log(err);
          });

    }

  }
    useEffect(() => {
        //alert("strategy_id :" + strategy_id);

        console.log("strategy_id: " + strategy_id)
        console.log("devide_type: " + devide_type)
    }, []);
    
    return (
                <>
                    <label htmlFor="all" style={{margin: "0px 0px 0px 5px"}}>[</label>
                    <input style={{margin: "0px 0px 0px 4px"}} type="radio" id={strategy_id+"_all"} name={strategy_id+"_all"} value="mlc"  checked={devide_type === "all" ? true : false}  onChange={() => chage_status_devide_type("all", strategy_id)} />
                    <label htmlFor="all" style={{margin: "0px 0px 0px 4px"}}>All</label>
           
                    <input style={{margin: "0px 0px 0px 4px"}}  type="radio" id={strategy_id+"_partial"} name={strategy_id+"_partial"} value="p2v"  checked={devide_type === "partial" ? true : false} onChange={() => chage_status_devide_type("partial", strategy_id)} />
                    <label htmlFor="partial" style={{margin: "0px 0px 0px 4px"}} >Partial</label>
                    <label htmlFor="all" style={{margin: "0px 0px 0px 4px"}}>]</label>
             
                </>
    );
}