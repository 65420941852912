import React, { useState, useEffect ,useCallback} from "react";
import { Route, Redirect } from "react-router-dom";



// DashBoard
import DashBoard from './DashBoard/DashBoard'

// Live Ticker
import LiveTicker from './liveTicker/LiveTicker'
// A-bot
import Abot from "./abot/Arbitrage";
import AbotbalanceLedger from "./abot/balanceLedger/abot_BalanceLedger";
import DailyJournal from "./abot/DailyJournal";
import IndividualJournal from "./abot/IndividualJournal";
import ABotSettings from "./abot/ABotSettings";
import InjectionRecords from "./abot/InjectionRecords";
// // C-bot
// import ConversionBot from "./cbot/ConversionBot";
// import CJournal from "./cbot/CJournal";
// // S-bot
import BotStatusCurrentBalance from "./sbot/dashBoard/BotStatusCurrentBalance";
import BalanceLedger from "./sbot/balanceLedger/BalanceLedger";
import ParingRecords from "./sbot/pairingRecords/PairingRecords";
import LogRecords from "./sbot/logRecords/LogRecords";
import WalletAssetRecords from "./sbot/walletAssetRecords​/WalletAssetRecords​";

// // P-bot
import PBotStatusCurrentBalance from "./pbot/dashBoard/BotStatusCurrentBalance";
import PBalanceLedger from "./pbot/balanceLedger/BalanceLedger";
import PParingRecords from "./pbot/pairingRecords/PairingRecords";
import PLogRecords from "./pbot/logRecords/LogRecords";
import PWalletAssetRecords from "./pbot/walletAssetRecords​/WalletAssetRecords​";
import PSendPascalMain from "./pbot/sendPascal/SendPascalMain";


// // F-bot
import FBotStatusCurrentBalance from "./fbot/dashBoard/BotStatusCurrentBalance";
import FBalanceLedger from "./fbot/balanceLedger/BalanceLedger";
import FParingRecords from "./fbot/pairingRecords/PairingRecords";
import FLogRecords from "./fbot/logRecords/LogRecords";
import FWalletAssetRecords from "./fbot/walletAssetRecords​/WalletAssetRecords​";
import FSendPascalMain from "./fbot/sendPascal/SendPascalMain";


// // M-bot
import MDashBoard from "./mbot/dashBoard/DashBoard";
import MBalanceAndLedger from "./mbot/balanceLedger/BalanceLedger";
import MPairingRecord from "./mbot/pairingRecords/PairingRecords";
import MLogRecords from "./mbot/logRecords/LogRecords";
import MWalletAssetRecords from "./mbot/walletAssetRecords​/WalletAssetRecords​";
// // Admin
import ApiMange from "./admin/ApiMange";
import CredentialOtp from "./admin/CredentialOtp";
import UserPage from "./admin/UserManagement/UserPage";
import FeeAndSharing from "./admin/feeAndSharing/FeeAndSharing";
import CoinManage from "./admin/coinManage/CoinManage";
import TradingLog from "./admin/log/TradingLog";
// // Ai
import Ai_Trading_Spot from "./ai/Ai_Trading_Spot";
// // Ai2
import Ai2_Trading_Spot from "./ai2/Ai2_Trading_Spot";

// // BackTesting
import BackTesting from "./backtesting/BackTesting";
import BackTesting_V2 from "./backtesting/BackTesting_V2";
import BackTesting_Auto from "./backtesting/BackTesting_Auto";
import BackTesting_Simuation from "./backtesting/BackTesting_Simuation";
import BackTesting_Simple_Testing from "./backtesting/BackTesting_Simple_Testing";
import BackTesting_Manual_Testing from "./backtesting/BackTesting_Manual_Testing";


function PagesPath({handleLogoutapp}) {
    
    return (
        <div>
            {/* DashBoard */}
            <Route path={"/DashBoard"} exact component={(props) => <DashBoard handleLogoutapp={handleLogoutapp}/>}/>


            {/* live ticker */}
            <Route path={"/liveticker"} exact component={(props) => <LiveTicker handleLogoutapp={handleLogoutapp}/>}/>

            {/* abot */}
            <Route exact path={"/arbitrage"}>
                <Redirect to="/arbitrage/a-dashboard" exact component={(props) => <Abot handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route path={"/arbitrage/a-dashboard"} exact component={(props) => <Abot handleLogoutapp={handleLogoutapp}/>} />
            
            <Route path={"/arbitrage/a-balance-ledger"} exact component={(props) => <AbotbalanceLedger handleLogoutapp={handleLogoutapp}/>} />
            <Route path="/arbitrage/a-bot" exact component={(props) => <ABotSettings handleLogoutapp={handleLogoutapp}/>}/>
            <Route path="/arbitrage/daily-journal" exact component={(props) => <DailyJournal handleLogoutapp={handleLogoutapp}/>}/>
            <Route path="/arbitrage/individual-journal" exact component={(props) => <IndividualJournal handleLogoutapp={handleLogoutapp}/>}/>
            <Route path="/arbitrage/a-injectoin" exact component={(props) => <InjectionRecords handleLogoutapp={handleLogoutapp}/>}/>
            

            {/* conversion */}
            {/* <Route exact path={"/conversion"}>
                <Redirect to="/conversion/c-bot" component={ConversionBot} />
            </Route>
            <Route exact path="/conversion/c-bot" component={ConversionBot} />
            <Route exact path="/conversion/c-journal" component={CJournal} /> */}

            {/* s-bot */}
            <Route exact path={"/trading"}>
                <Redirect to="/trading/t-dashboard" exact component={(props) => <BotStatusCurrentBalance handleLogoutapp={handleLogoutapp}/>} />
            </Route>
            <Route  path={"/trading/t-dashboard"}  exact component={(props) => <BotStatusCurrentBalance handleLogoutapp={handleLogoutapp}/>} />
            <Route  path="/trading/t-balance-ledger" exact  component={(props) => <BalanceLedger handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/trading/t-pairing-records" exact component={(props) => <ParingRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/trading/t-log-records" exact component={(props) => <LogRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/trading/t-wallet-asset-records" exact component={(props) => <WalletAssetRecords handleLogoutapp={handleLogoutapp}/>}/>

            {/* m-bot */}
            <Route exact path={"/m_trading"}>
                <Redirect to="/m_trading/m-dashboard" exact component={(props) => <MDashBoard handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/m_trading/m-dashboard" component={(props) => <MDashBoard handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/m_trading/m-balance-ledger"  component={(props) => <MBalanceAndLedger handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/m_trading/m-pairing-records" component={(props) => <MPairingRecord handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/m_trading/m-log-records" component={(props) => <MLogRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/m_trading/m-wallet-asset-records" component={(props) => <MWalletAssetRecords handleLogoutapp={handleLogoutapp}/>}/>

            {/* p-bot */}
            <Route exact path={"/p_trading"}>
                <Redirect to="/p_trading/p-dashboard" exact component={(props) => <PBotStatusCurrentBalance handleLogoutapp={handleLogoutapp} source={'p_bot'}/>} />
            </Route>
            <Route  path={"/p_trading/p-dashboard"}  exact component={(props) => <PBotStatusCurrentBalance handleLogoutapp={handleLogoutapp} source={'p_bot'}/>} />
            <Route  path="/p_trading/p-balance-ledger" exact  component={(props) => <PBalanceLedger handleLogoutapp={handleLogoutapp} source={'p_bot'}/>}/>
            <Route  path="/p_trading/p-pairing-records" exact component={(props) => <PParingRecords handleLogoutapp={handleLogoutapp} source={'p_bot'}/>}/>
            <Route  path="/p_trading/p-log-records" exact component={(props) => <PLogRecords handleLogoutapp={handleLogoutapp} source={'p_bot'}/>}/>
            <Route  path="/p_trading/p-wallet-asset-records" exact component={(props) => <PWalletAssetRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/p_trading/p-send-pascal" exact component={(props) => <PSendPascalMain handleLogoutapp={handleLogoutapp} source={'p_bot'}/>}/>


            {/* p-bot-optimize */}
            <Route exact path={"/p_trading_opti"}>
                <Redirect to="/p_trading_opti/p-dashboard_opti" exact component={(props) => <PBotStatusCurrentBalance handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>} />
            </Route>

            <Route exact path="/p_trading_opti/manual_optimization" component={(props) => <BackTesting_Manual_Testing handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/p_trading_opti/auto_optimization_spot" component={(props) => <BackTesting_Auto handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/p_trading_opti/live_trading_spot" component={(props) => <BackTesting_Simuation handleLogoutapp={handleLogoutapp}/>}/>

            <Route  path={"/p_trading_opti/p-dashboard_opti"}  exact component={(props) => <PBotStatusCurrentBalance handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>} />
            <Route  path="/p_trading_opti/p-balance-ledger_opti" exact  component={(props) => <PBalanceLedger handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>}/>
            <Route  path="/p_trading_opti/p-pairing-records_opti" exact component={(props) => <PParingRecords handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>}/>
            <Route  path="/p_trading_opti/p-log-records_opti" exact component={(props) => <PLogRecords handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>}/>
            <Route  path="/p_trading_opti/p-wallet-asset-records_opti" exact component={(props) => <PWalletAssetRecords handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>}/>
            <Route  path="/p_trading_opti/p-send-pascal_opti" exact component={(props) => <PSendPascalMain handleLogoutapp={handleLogoutapp} source={'p_bot_optimize'}/>}/>

            {/* f-bot */}
            <Route exact path={"/f_trading"}>
                <Redirect to="/f_trading/f-dashboard" exact component={(props) => <FBotStatusCurrentBalance handleLogoutapp={handleLogoutapp}/>} />
            </Route>
            <Route  path={"/f_trading/f-dashboard"}  exact component={(props) => <FBotStatusCurrentBalance handleLogoutapp={handleLogoutapp}/>} />
            <Route  path="/f_trading/f-balance-ledger" exact  component={(props) => <FBalanceLedger handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/f_trading/f-pairing-records" exact component={(props) => <FParingRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/f_trading/f-log-records" exact component={(props) => <FLogRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/f_trading/f-wallet-asset-records" exact component={(props) => <FWalletAssetRecords handleLogoutapp={handleLogoutapp}/>}/>
            <Route  path="/f_trading/f-send-pascal" exact component={(props) => <FSendPascalMain handleLogoutapp={handleLogoutapp} source={'f_bot'}/>}/>



            {/* admin */}
            <Route exact path="/admin">
                <Redirect to="/admin/api-management" exact component={(props) => <ApiMange handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/admin/api-management" component={(props) => <ApiMange handleLogoutapp={handleLogoutapp}/>}/>
            {/* <Route exact path="/admin/credential-otp" component={(props) => <CredentialOtp handleLogoutapp={handleLogoutapp}/>}/> */}
            <Route exact path="/admin/user" component={(props) => <UserPage handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/admin/coin_management" component={(props) => <CoinManage handleLogoutapp={handleLogoutapp}/>}/>
            <Route exact path="/admin/fee-sharing" component={FeeAndSharing} />
            <Route exact path="/admin/view_full_log" component={(props) => <TradingLog handleLogoutapp={handleLogoutapp}/>}/>

             {/* ai */}
             <Route exact path="/ai">
                <Redirect to="/ai/ai_trading_spot" exact component={(props) => <Ai_Trading_Spot handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/ai/ai_trading_spot" component={(props) => <Ai_Trading_Spot handleLogoutapp={handleLogoutapp}/>}/>
             {/* ai2 */}
             <Route exact path="/ai2">
                <Redirect to="/ai2/ai2_trading_spot" exact component={(props) => <Ai2_Trading_Spot handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/ai2/ai2_trading_spot" component={(props) => <Ai2_Trading_Spot handleLogoutapp={handleLogoutapp}/>}/>

            {/* BackTesting */}
            
            <Route exact path="/optimization">
                <Redirect to="/optimization/simple_testing" exact component={(props) => <BackTesting_Simple_Testing handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/optimization/simple_testing" component={(props) => <BackTesting_Simple_Testing handleLogoutapp={handleLogoutapp}/>}/>


            {/* 
            <Route exact path="/optimization">
                <Redirect to="/optimization/manual_optimization" exact component={(props) => <BackTesting_V2 handleLogoutapp={handleLogoutapp}/>}/>
            </Route>
            <Route exact path="/optimization/manual_optimization" component={(props) => <BackTesting_V2 handleLogoutapp={handleLogoutapp}/>}/>
            */}
        </div>
    );
}

export default PagesPath;
