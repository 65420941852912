
import React, { useState, useEffect } from "react";
import axios from "axios";

export function WalletSelector({ strategy_id,strategy_group,get_backtesting_status_all}) {

    function update_strategy_group(strategy_group_type){

        if (window.confirm('Do you want to apply ?')) {
            //console.log("get_candle_realtime ! ")
            const python_server_url = process.env.REACT_APP_PYTHON_SERVER_AI2_TRADING
            const data = {
                strategy_id : strategy_id,
                strategy_group: strategy_group_type,
            };
    
            var turl = "update_strategy_group"
        
            axios
              .post(`${python_server_url}/${turl}`, data)
              .then((res) => {
                console.log(JSON.stringify(res.data))
                if (res.data.status == "ok") {
                   
                    console.log("wallet is updated!")
                    get_backtesting_status_all()
                }
              })
              .catch((err) => {
                alert(err);
                console.log(err);
              });
    
        }
      }

    useEffect(() => {
        //alert("strategy_id :" + strategy_id);

        console.log("strategy_id: " + strategy_id)
        console.log("strategy_group: " + strategy_group)
    }, []);
    
    return (
                <>
                    &emsp;Wallet&emsp;:&emsp; 
                    <input type="radio" id={strategy_id+"_mlc"} name={strategy_id+"_mlc"} value="mlc"  checked={strategy_group === "1" ? true : false}  onChange={() => update_strategy_group("1")} />
                    <label htmlFor="mlc">MLC</label>
                    &emsp;
                    <input type="radio" id={strategy_id+"_p2v"} name={strategy_id+"_p2v"} value="p2v"checked={strategy_group === "2" ? true : false}  onChange={() => update_strategy_group("2")} />
                    <label htmlFor="p2v">P2V</label>
                    &emsp;
                    <input type="radio" id={strategy_id+"_tbt"} name={strategy_id+"_tbt"} value="tbt" checked={strategy_group === "3" ? true : false}  onChange={() => update_strategy_group("3")} />
                    <label htmlFor="tbt">TBT</label>
                    &emsp;
                </>
    );
}